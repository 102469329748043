.coin-cards-list {
  margin-top: 20px;
  padding-bottom: 120px;
}

.coin-card {
  height: 65px;
  display: flex;
  margin-left: 10px;
}
.icon {
  position: relative;
  width: 65px;
  height: 65px;
}
.icon-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
}
.icon-flex > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.icon-flex > .percentage {
  position: absolute;
  top: 3px;
  right: 3px;
  width:20px;
  height:20px;
  border-radius:10px;
  font-size:8px;
  color:#fff;
  line-height:20px;
  text-align:center;
  background:#000
}
.coin-info {
  width: 50%;
}
.coin-name {
  font-size: 18.5px;
  margin: 0;
  margin-top: 6px;
  margin-left: 6px;
}
.coin-price {
  font-size: 12.5px;
  margin: 0;
  margin-left: 6px;
}


.holdings-info {
  width: 30%;
}
.holdings-value {
  font-size: 16.5px;
  margin: 0;
  margin-top: 6px;
}
.holdings-amount {
  font-size: 12.5px;
  margin: 0;
}
