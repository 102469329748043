
* {
  font-family: var(--main-font);
  -webkit-tap-highlight-color: rgba(0,0,0,0); // remove annoying blue box on mobile => https://stackoverflow.com/a/45050338/6272061
}

body {
  background: rgb(250,250,250);
  background: #e2e9fd;
  background: white;
  color: rgb(15,15,15);
  font-family: var(--main-font);
  margin: 0 auto;
}
h1 {
  font-weight: 600;
}

.container-main {
  max-width: 400px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #f9fbfd;
  position: relative;
  overflow-y:hidden;
}

/*
 * TEXT ALIGN
 */
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.text-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
 * POSITIONING
 */
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.pos-absolute {
  position: absolute;
}
.pos-relative {
  position: relative;
}

/*
 * VISIBILITY
 */
.transparent {
  opacity: 0;
}
.hidden {
  visibility: hidden;
}
