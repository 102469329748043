.currency-selector {
  background-color: #f9fbfd;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  transform: translate(100%);
  transition: all 1s ease;
  transition: all 0.3s ease-out;
  text-align: center;

  &.is-open {
    transform: translate(0);
  }

  .currency-selector-option {
    font-size: 19px;
    cursor: pointer;
    padding:1px;
  }

  h1 {
    background-color: #293c58;
    margin: 0;
    padding: 0;
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 75px;
    line-height: 75px;
    font-size: 26px;
  }
}
