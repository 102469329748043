.header {
  background: #525252;
  background: white;
  background: slateblue;
  background: #4f5d73;
  background: #293c58;
  color: #525252;
  color: white;
  height: 75px;
  width: 100%;
  max-width: 400px;
  position: fixed;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0 0 13px rgba(0,0,0,0.3);
  z-index: 2;
}

.header p {
  margin: 0 auto;
  /*margin-left: 79px;*/
  /*margin-right: 29px;*/
  text-align: center;
}
.header h1 {
  margin-top: 16px;
  margin-bottom: 16px;
  /* margin-left: 34px; */
  font-size: 31px;
}
p#portfolio-value-label {
  font-size: 12px;
  font-weight: 400;
}
p#portfolio-value {
  font-size: 24px;
  font-weight: 600;
}

/*img.logo {
  height: 75px;
}
img.logo:hover {
  transform: rotate(-180deg);
  top: -200px;
  right: -100px;
}*/

.overview {
  margin-top: 11px;
}

/* MENU */
.menu {
  position: absolute;
  left: 4px;
  top: 6px;
  cursor: pointer;
  padding: 20px;
}
.menu > div {
  width: 22px;
  height: 2px;
  background-color: rgba(255,255,255,0.5);
  margin: 4px 0;
}
/* CURRENCY */
.currency {
  position: absolute;
  top: 7px;
  right: 10px;
  color: rgba(255,255,255,0.8);
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
}

/* THE CONTENT BEHIND THE STICKY HEADER */
/* ...pull to refresh... */
/* ...needs same height as the header! */
.placeholder-update-timestamp {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholder-update-timestamp {
  text-align: center;
}



/**
 * LOADING SPINNER1
 * => https://tobiasahlin.com/spinkit/
 */
.spinner-tobiasahlin {
  width: 10px;
  height: 10px;
  position: relative;
  margin: 0;
}
.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: lightcoral;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
// custom spinner container
.spinner-container {
  position: absolute;
  right: 20px;
  top: 24px;
}
